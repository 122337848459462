@import '@/components/variables.scss';

* {
  box-sizing: border-box;
  font-family: var(--fontFamily);
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

button {
  display: flex;
  flex: 0 0 auto;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  border-width: 0;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  appearance: none;
  background-color: none;
  background: none;
  border: 0px;
  outline: none;
  box-shadow: none;
}

.disclaimer {
  display: block;
  padding-top: spacer(xl);
  font-size: .675rem;
  text-align: justify;
  a {
    color: var(--disclaimerLinkColor, '#0c0c6f');
    text-decoration: underline;
  }
  &-inline {
    padding-top: 0;
    font-size: .875rem;
  }
}

.text-align-left { text-align: left; }
.text-align-right { text-align: right; }
.text-align-center { text-align: center; }

.text-red { color: var(--colorRed) };
.text-bold { font-weight: 700; }

section {

  display: block;
  padding-bottom: spacer(xl);

  &:last-child {
    padding-bottom: 0;
  }

  &.white {
    background-color: white;
    padding-top: spacer(xl);
  }

}

.column {
  display: block;
  width: 100%;
  max-width: var(--maxWidth);
  margin: auto;
  position: relative;
  padding-left: spacer(xl);
  padding-right: spacer(xl);
}

@each $bp in $x-breakpoints {
  .min-b-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-b-#{$bp} {
      display: block !important;
    }
  }
  .min-i-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-i-#{$bp} {
      display: inline-block !important;
    }
  }
  .min-f-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-f-#{$bp} {
      display: flex !important;
    }
  }
  .max-b-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-b-#{$bp} {
      display: block !important;
    }
  }
  .max-i-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-i-#{$bp} {
      display: inline-block !important;
    }
  }
  .max-f-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-f-#{$bp} {
      display: flex !important;
    }
  }
}
