@import '@/components/variables.scss';

.button {

  &-base {
    background-color: var(--primaryColor);
    color: var(--primaryText);
    font-weight: 700;
    font-size: 1rem;
    padding-left: spacer(md);
    padding-right: spacer(md);
    display: flex;
    height: 44px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: var(--buttonBorderRadius);
    border: 1px solid transparent;
    width: 100%;
    position: relative;
    
    span {
      flex: 0 0 auto;
      + i {
        margin-left: spacer(xs)
      }
      + svg {
        margin-left: spacer(xs)
      }
    }
    
    i {
      + span {
        margin-left: spacer(xs)
      }
      svg {
        height: 16px;
      }
      
    }
    
    > svg {
      display: none;
    }
    
    &:disabled {
      background-color: #DDDDDD;
      color: #999999;
      span {
        color: #999999;
      }
    }

  }
  
  &-rows {
    flex-direction: column;
  }
  
  &-primary {
    background-color: var(--primaryColor);
    color: var(--primaryText);
    span {
      color: var(--primaryText);
    }
  }

  &-primary-line {
    background-color: white;
    border-color: var(--primaryColor);
  }
  
  &-line {
    background-color: transparent;
    border-color: var(--primaryColor);
    color: var(--primaryColor);
    span {
      color: var(--primaryColor);
    }
  }
  
  &-processing {
    color: #FFF;
    background-color: rgba(0,0,0,0.3);
    overflow: hidden;
    span {
      display: none;
    }
    > svg {
      height: 24px;
      display: block;
      flex: 0 0 auto;
      animation: processingSpinner 1s infinite, processingZoom 1s;
    }
  }
  
  &-icon {
    width: 44px;
    padding: 0 0;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &-icon-only {
    background-color: transparent;
    border-color: transparent;
    color: black;
  }

  &-green {
    background-color: var(--colorGreen);
    color: var(--primaryText);
    span {
      color: var(--primaryText);
    }
  }

  &-red {
    background-color: var(--colorRed);
    color: var(--primaryText);
    span {
      color: var(--primaryText);
    }
  }

  &-link {
    background-color: transparent;
    border-color: transparent;
    color: var(--primaryColor);
    height: auto;
  }

}

@keyframes processingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes processingZoom {
  0% {
    height: 300px;
  }
  100% {
    height: 24px;
  }
}